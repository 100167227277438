<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <div>
          <!-- Reproductor de audio que se reproduce automáticamente cuando hay una URL válida -->
          <audio
            ref="audioPlayer"
            :src="audioUrl"
            v-if="audioUrl"
            @canplay="playAudio"
            controls
            autoplay
            style="display: none"
          >
            <!-- Si el navegador no soporta el formato -->
            Tu navegador no soporta el elemento de audio.
          </audio>
        </div>
        <h1 class="text-center">{{ $t('Copla.Views.Synthesis.Title') }}</h1>
        <div class="row">
          <div class="border rounded secciones mt-4 col-6" id="configuration">
            <h2>{{ $t('Copla.Common.Subtitles.Configuration') }}</h2>
            <div class="form-group row container">
              <label for="server" class="col-3 col-form-label">{{
                $t('Copla.Common.Form.Language')
              }}</label>
              <div class="col-9">
                <select v-model="language" class="custom-select">
                  <option
                    v-bind:value="id"
                    v-for="(value, id) in filteredLanguages"
                    :key="id"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <label for="server" class="mt-2 col-3 col-form-label">{{
                $t('Copla.Common.Form.Voice1')
              }}</label>
              <div class="col-9 mt-2">
                <select v-model="voice" class="custom-select">
                  <option
                    :value="value"
                    v-for="(value, id) in voicesSelect"
                    :key="id"
                  >
                    {{ value.Name }}
                  </option>
                </select>
              </div>
              <label for="server" class="mt-2 col-3 col-form-label">{{
                $t('Copla.Common.Form.Voice2')
              }}</label>
              <div class="col-9 mt-2">
                <select v-model="voice2" class="custom-select">
                  <option
                    :value="value"
                    v-for="(value, id) in voicesSelectF"
                    :key="id"
                  >
                    {{ value.Name }}
                  </option>
                </select>
              </div>
              <label for="server" class="mt-2 col-3 col-form-label">{{
                $t('Copla.Common.Form.DefaultVoice')
              }}</label>
              <div class="col-9 mt-2">
                <select v-model="defaultVoice" class="custom-select">
                  <option value="H">{{ $t('Copla.Common.Form.Male') }}</option>
                  <option value="M">
                    {{ $t('Copla.Common.Form.Female') }}
                  </option>
                </select>
              </div>
              <label for="server" class="mt-2 col-3 col-form-label">{{
                $t('Copla.Common.Form.Speed')
              }}</label>
              <div class="col-9 mt-2">
                <select v-model="speed" class="custom-select">
                  <option
                    :value="value.id"
                    v-for="(value, id) in speedsList"
                    :key="id"
                  >
                    {{ value.value }}
                  </option>
                </select>
              </div>
              <label for="server" class="mt-2 col-3 col-form-label">{{
                $t('Copla.Common.Form.Format')
              }}</label>
              <div class="col-9 mt-2">
                <select v-model="format" class="custom-select">
                  <option
                    :value="value"
                    v-for="(value, id) in formats"
                    :key="id"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="border rounded secciones mt-4 col-6" id="immediate">
            <h2>{{ $t('Copla.Views.Synthesis.Immediate') }}</h2>
            <div class="form-group">
              <textarea
                class="form-control"
                v-model="text"
                :placeholder="placeholder"
                rows="5"
              ></textarea>
            </div>
            <div class="row mt-3">
              <div class="col mr-3">
                <div class="text-center">
                  <button
                    type="button"
                    id="play"
                    name="play"
                    class="btn btn-success"
                    v-on:click="play({ text: text, speed: '' })"
                  >
                    {{ $t('Copla.Common.Buttons.Play') }}
                  </button>
                  <button
                    type="button"
                    id="download"
                    name="download"
                    class="btn ml-3 btn-primary"
                    v-on:click="download({ text: text, speed: '' })"
                  >
                    {{ $t('Copla.Common.Buttons.Download') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="border rounded secciones mt-4 col-12">
            <h2>{{ $t('Copla.Views.Synthesis.ScriptLoad') }}</h2>
            <div class="form-group row">
              <label for="file" class="col-2 col-form-label text-center">
                {{ $t('Copla.Views.Synthesis.AudioScript') }} (.sti/.srt)
              </label>
              <div class="col-7">
                <input
                  type="file"
                  class="form-control"
                  id="file"
                  name="file"
                  ref="file"
                  v-on:change="handleFileUpload()"
                  accept=".sti,.srt"
                  required
                />
              </div>
              <div class="text-center col-3">
                <button
                  type="button"
                  id="upload"
                  name="upload"
                  class="btn btn-primary"
                  v-on:click="uploadFile"
                  v-if="file != ''"
                >
                  {{ $t('Copla.Common.Buttons.Load') }}
                </button>
                <button
                  type="button"
                  id="downloadZip"
                  name="downloadZip"
                  class="btn btn-primary ml-2"
                  v-on:click="downloadZip"
                  v-if="script.length > 0"
                >
                  {{ $t('Copla.Common.Buttons.DownloadZIP') }}
                </button>
              </div>
            </div>
            <div
              v-if="error != ''"
              class="border rounded secciones mt-4"
              id="error"
            >
              {{ error }}
            </div>
          </div>
        </div>
        <table
          class="table table-sm table-striped table-dark mt-4"
          v-if="script.length > 0"
        >
          <thead>
            <tr class="d-flex">
              <th class="col col-xl-1">#</th>
              <th class="col col-xl-5">
                {{ $t('Copla.Views.Synthesis.Table.Text') }}
              </th>
              <th class="col col-xl-2">
                {{ $t('Copla.Common.Form.Voice') }}
              </th>
              <th class="col col-xl-1">
                {{ $t('Copla.Common.Form.Speed') }}
              </th>
              <th class="col col-xl-3">
                {{ $t('Copla.Views.Synthesis.Table.Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(text, i) in script" :key="i" :id="i" class="d-flex">
              <td class="col col-xl-1" style="vertical-align: top">
                {{ padZeros(i) }}
              </td>
              <td class="col col-xl-5" style="vertical-align: top">
                {{ text.text }}
              </td>
              <td class="col col-xl-2">
                <select v-model="script[i].voice" class="custom-select">
                  <option
                    :value="value"
                    v-for="(value, id) in voicesSelectAll"
                    :key="id"
                  >
                    {{ value.Name }} ( {{ value.Gender }})
                  </option>
                </select>
              </td>
              <td class="col col-xl-1">
                <select v-model="script[i].speed" class="custom-select">
                  <option
                    :value="value.id"
                    v-for="(value, id) in speedsList"
                    :key="id"
                  >
                    {{ value.value }}
                  </option>
                </select>
              </td>
              <td class="col col-xl-3">
                <button
                  type="button"
                  v-on:click="playScript(i)"
                  class="btn btn-success"
                >
                  {{ $t('Copla.Common.Buttons.Play') }}
                </button>
                <button
                  type="button"
                  v-on:click="downloadScript(i)"
                  class="btn btn-primary ml-2"
                >
                  {{ $t('Copla.Common.Buttons.Download') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
//import axios from "axios";
import MainMenu from '../components/MainMenu'
import AzureSynthesizer from '../js/AzureSynthesizer'
import AzureAPISynthesizer from '../js/AzureAPISynthesizer'
import formatSubtitlesAPI from '../js/formatSubtitlesAPI'
import ModalLoading from '../components/ModalLoading.vue'
export default {
  name: 'Synthesis2',
  components: {
    MainMenu,
    ModalLoading
  },
  data () {
    return {
      text: '',
      languages: this.$t('Copla.Common.Lists.Languages'),
      filteredLanguages: {},
      voices: [],
      voicesSelect: {},
      voicesSelectF: {},
      voicesSelectAll: {},
      defaultVoice: 'H',
      language: 'es-ES',
      voice: '',
      voice2: '',
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: '',
      script: [],
      showModalLoading: false,
      messageModal: '',
      error: '',
      loading: 0,
      percentage: 0,
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      placeholder: this.$t('Copla.Views.Synthesis.Placeholder'),
      speedsList: [],
      formats: ['mp3', 'wav', 'ogg', '3gp'],
      speed: '',
      format: 'wav',
      audioUrl: null,
      speedADs: [],
      adCodes: { R: '+15%', MR: '+30%' },
      adCodesSex: ['H','M']
    }
  },
  mounted () {
    let self = this
    this.speedsList = this.speeds()
    let azureAPISynthesizer = new AzureAPISynthesizer({
      key: process.env.VUE_APP_AZURE_KEY,
      location: process.env.VUE_APP_AZURE_LOCATION
    })
    azureAPISynthesizer.getVoicesList().then(response => {
      self.voices = response.data.locale
      self.voices[self.language].forEach(voice => {
        if (voice.Gender == 'Male' && self.voice == '') {
          self.voice = voice
        }
        if (voice.Gender == 'Female' && self.voice == '') {
          self.voice2 = voice
        }
      })
      self.filteredLanguages = Object.keys(self.languages)
        .filter(key => Object.prototype.hasOwnProperty.call(self.voices, key))
        .reduce((acc, key) => {
          acc[key] = self.languages[key]
          return acc
        }, {})
      const entries = Object.entries(self.filteredLanguages)
      entries.sort(([, value1], [, value2]) => value1.localeCompare(value2))
      self.filteredLanguages = entries.reduce((acc, curr) => {
        acc[curr[0]] = curr[1]
        return acc
      }, {})
    })
  },
  watch: {
    voices: function (val) {
      if (val.length == 0) {
        this.voicesSelect = {}
        this.voicesSelectF = {}
        this.voicesSelectAll = {}
      } else {
        let self = this
        let list = self.voices[self.language]
        let newListM = []
        let newListF = []
        list.forEach(function (value) {
          if (value.Gender == 'Male') {
            newListM.push(value)
          }
          if (value.Gender == 'Female') {
            newListF.push(value)
          }
        })
        this.voicesSelect = newListM
        this.voicesSelectF = newListF
        this.voicesSelectAll = [...this.voicesSelect, ...this.voicesSelectF]
        this.voice = this.voicesSelect[0]
        this.voice2 = this.voicesSelectF[0]
      }
    },
    language: function (val) {
      let self = this
      let list = self.voices[val]
      let newListM = []
      let newListF = []
      list.forEach(function (value) {
        if (value.Gender == 'Male') {
          newListM.push(value)
        }
        if (value.Gender == 'Female') {
          newListF.push(value)
        }
      })
      this.voicesSelect = newListM
      this.voicesSelectF = newListF
      this.voicesSelectAll = [...this.voicesSelect, ...this.voicesSelectF]
      this.voice = this.voicesSelect[0]
      this.voice2 = this.voicesSelectF[0]
    }
  },
  methods: {
    padZeros (number) {
      return String(number + 1).padStart(3, '0')
    },
    setAudioUrl (responseUrl) {
      // Reemplazar la ruta local con la URL pública
      this.audioUrl = responseUrl.replace(
        '/var/www/html/public',
        'https://formatsubtitles.copla.io'
      )
    },
    playAudio () {
      const audioPlayer = this.$refs.audioPlayer
      if (audioPlayer) {
        audioPlayer.play() // Forzar la reproducción
      }
    },
    speeds () {
      let values = []
      for (let i = -100; i < 0; i++) {
        let id = i + '%'
        let value = i + '%'
        values.push({ id: id, value: value })
      }
      values.push({ id: '', value: 'Normal' })
      for (let i = 1; i <= 200; i++) {
        let id = '+' + i + '%'
        let value = '+' + i + '%'
        values.push({ id: id, value: value })
      }
      return values
    },
    showModal (status, message = '') {
      this.messageModal = message
      this.showModalLoading = status
    },
    uploadFile: function () {
      this.showModal(true, this.$t('Copla.Common.Modal.Uploading'))
      let formData = new FormData()
      let splitFile = this.file.name.split('.')
      let extension = splitFile[splitFile.length - 1]
      formData.append('_file', this.file)
      this.fsAPI.uploadFile(formData).then(response => {
        if (!response.error) {
          this.showModal(false)
          if (extension == 'srt') {
            this.postSRT(response.data.data)
          } else if (extension == 'sti') {
            this.postStartit(response.data.data)
          }
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    postSRT: function (url) {
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      let params = {
        _url: url
      }
      this.fsAPI.postSRT(params).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          this.getSubtitles(response.data.data.caf)
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    postStartit: function (url) {
      let self = this
      this.showModal(true, this.$t('Copla.Common.Modal.Creating'))
      let params = {
        _url: url,
        _language: self.language
      }
      this.fsAPI.postStartit(params).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          this.getAudiodescriptions(response.data.data.caf)
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    getSubtitles: function (id) {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.fsAPI.getSubtitles(id).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          let text = ''
          response.data.data.forEach(function (subs) {
            subs.lines.forEach(function (sub) {
              text += sub.text + ' '
            })
            self.script.push({ text: text, speed: '' })
            text = ''
          })
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    getAudiodescriptions: function (id) {
      this.showModal(true, this.$t('Copla.Common.Modal.Loading'))
      let self = this
      this.fsAPI.getAudiodescriptions(id).then(response => {
        if (!response.data.error) {
          this.showModal(false)
          response.data.data.forEach(function (ads) {
            if (
              ads.comment == '' ||
              ads.comment === undefined 
              //|| self.adCodes[ads.comment] === undefined
            ) {            
              if (self.defaultVoice == 'H') {
                self.script.push({ text: ads.text, speed: '', voice: self.voice })
              }
              else {
                self.script.push({ text: ads.text, speed: '', voice: self.voice2 })
              }
            } else {
              let commentSplit = ads.comment.split(',')
              let text = ads.text
              let speed = ''
              let voice = self.voice
              if (!commentSplit.includes('M') && !commentSplit.includes('H')) {
                if (self.defaultVoice == 'M') {
                  voice = self.voice2
                }
              }
              else if (commentSplit.includes('M')) {
                voice = self.voice2
              }
              commentSplit.forEach((comment) => {
                if (self.adCodes[comment] !== undefined) {
                  speed = self.adCodes[comment]
                }
              })
              self.script.push({
                text: text,
                speed: speed,
                voice: voice
              })
            }
          })
        } else {
          this.showModal(false)
          this.error = 'Error: ' + response.data
        }
      })
    },
    downloadZip: function () {
      let self = this
      let time = 0
      if (self.format == 'mp3') {
        time = Math.floor(self.script.length / 3)
      } else {
        time = Math.floor(self.script.length / 2.5)
      }
      this.showModal(
        true,
        this.$t('Copla.Common.Modal.Creating') +
          ' tiempo estimado ' +
          time +
          ' segundos'
      )
      let params = {
        _phrases: self.script,
        _language: this.language,
        _voice: this.voice.ShortName,
        _format: this.format,
        _speed: this.speed,
        _sex: this.voice.Gender,
        _zip: 1
      }
      this.fsAPI.postADs(params).then(response => {
        self.showModal(false)
        if (!response.data.error) {
          let url = response.data.data.replace(
            '/var/www/html/public',
            'https://formatsubtitles.copla.io'
          )
          let element = document.createElement('a')
          element.setAttribute('href', url)
          element.setAttribute('download', 'ads.zip')
          element.style.display = 'none'
          element.click()
        } else {
          console.log('error')
        }
      })
    },
    playScript: function (i) {
      this.play(this.script[i])
    },
    downloadScript: function (i) {
      this.download(this.script[i])
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    downloadFile: function (data, fileName) {
      var blob = new Blob([data], {
        type: 'audio/wav; codecs=MS_PCM'
      })
      let url = URL.createObjectURL(blob)
      let element = document.createElement('a')
      element.setAttribute('href', url)
      element.setAttribute('download', fileName)
      element.style.display = 'none'
      element.click()
    },
    play: function (text) {
      let self = this
      let params = {
        _phrases: [text],
        _language: this.language,
        _voice: this.voice.ShortName,
        _format: this.format,
        _speed: this.speed,
        _sex: this.voice.Gender,
        _zip: 0
      }
      if (this.defaultVoice == 'M') {
        params._voice = this.voice2.ShortName
        params._sex = this.voice2.Gender
      }
      this.fsAPI.postADs(params).then(response => {
        if (!response.data.error) {
          self.setAudioUrl(response.data.data[0])
        } else {
          console.error(response)
        }
      })
    },
    downloadBlobFile: function (remoteUrl) {
      let self = this
      let url = remoteUrl.replace(
        '/var/www/html/public',
        'https://formatsubtitles.copla.io'
      )
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al descargar el archivo')
          }
          return response.blob()
        })
        .then(blob => {
          let urlBlob = URL.createObjectURL(blob)
          let element = document.createElement('a')
          element.setAttribute('href', urlBlob)
          element.setAttribute('download', 'ad.' + self.format)
          element.style.display = 'none'

          document.body.appendChild(element)

          element.click()

          document.body.removeChild(element)
          URL.revokeObjectURL(urlBlob)
        })
        .catch(error => {
          console.error('Error al descargar el archivo:', error)
        })
    },
    download: function (text) {
      let self = this
      let params = {
        _phrases: [text],
        _language: this.language,
        _voice: this.voice.ShortName,
        _format: this.format,
        _speed: this.speed,
        _sex: this.voice.Gender,
        _zip: 0
      }
      if (this.defaultVoice == 'M') {
        params._voice = this.voice2.ShortName
        params._sex = this.voice2.Gender
      }
      this.fsAPI.postADs(params).then(response => {
        if (!response.data.error) {
          self.downloadBlobFile(response.data.data[0])
        } else {
          console.error(response)
        }
      })
    },
    connect: function (params, fileName) {
      let self = this
      params.language = this.language
      params.voice = this.voice
      let azureSynth = new AzureSynthesizer(params)
      azureSynth.createFile().then(response => {
        if (params.mode == 1) {
          self.downloadFile(response, fileName)
        }
      })
    }
  }
}
</script>

<style scoped>
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}

.loading {
  font-size: 1.5em;
  font-weight: bold;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#error {
  font-size: 1.2em;
  color: red;
  font-weight: bold;
}
</style>
